/* tslint:disable */
/* eslint-disable */
/**
 * Thumbnails Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * A response model for thumbnails which belong to a specific universe ID
 * @export
 * @interface RobloxThumbnailsApiModelsThumbnailMetaDataResponse
 */
export interface RobloxThumbnailsApiModelsThumbnailMetaDataResponse {
    /**
     * webapp use cache
     * @type {boolean}
     * @memberof RobloxThumbnailsApiModelsThumbnailMetaDataResponse
     */
    isWebappUseCacheEnabled?: boolean;
    /**
     * webapp caching expiration in ms
     * @type {string}
     * @memberof RobloxThumbnailsApiModelsThumbnailMetaDataResponse
     */
    webappCacheExpirationTimspan?: string;
}
/**
 * A response model for thumbnails which belong to a specific universe ID
 * @export
 * @interface RobloxThumbnailsApiModelsUniverseThumbnailsResponse
 */
export interface RobloxThumbnailsApiModelsUniverseThumbnailsResponse {
    /**
     * Integer universe ID
     * @type {number}
     * @memberof RobloxThumbnailsApiModelsUniverseThumbnailsResponse
     */
    universeId?: number;
    /**
     * 
     * @type {RobloxWebWebAPIModelsApiErrorModel}
     * @memberof RobloxThumbnailsApiModelsUniverseThumbnailsResponse
     */
    error?: RobloxWebWebAPIModelsApiErrorModel;
    /**
     * An array of ThumbnailResponse objects
     * @type {Array<RobloxWebResponsesThumbnailsThumbnailResponse>}
     * @memberof RobloxThumbnailsApiModelsUniverseThumbnailsResponse
     */
    thumbnails?: Array<RobloxWebResponsesThumbnailsThumbnailResponse>;
}
/**
 * 
 * @export
 * @interface RobloxThumbnailsApisModelsThumbnailBatchRequest
 */
export interface RobloxThumbnailsApisModelsThumbnailBatchRequest {
    /**
     * The request id. (Generated client side, used to represent the items in the request)
     * @type {string}
     * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
     */
    requestId?: string;
    /**
     * The thumbnail target id
     * @type {number}
     * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
     */
    targetId?: number;
    /**
     * The type of the thumbnails
     * @type {string}
     * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
     */
    token?: string;
    /**
     * The type of the thumbnails
     * @type {string}
     * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
     */
    type?: RobloxThumbnailsApisModelsThumbnailBatchRequestTypeEnum;
    /**
     * The thumbnail size
     * @type {string}
     * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
     */
    size?: string;
    /**
     * Is the thumbnail circular shape
     * @type {boolean}
     * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
     */
    isCircular?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxThumbnailsApisModelsThumbnailBatchRequestTypeEnum {
    Avatar = 'Avatar',
    AvatarHeadShot = 'AvatarHeadShot',
    GameIcon = 'GameIcon',
    BadgeIcon = 'BadgeIcon',
    GameThumbnail = 'GameThumbnail',
    GamePass = 'GamePass',
    Asset = 'Asset',
    BundleThumbnail = 'BundleThumbnail',
    Outfit = 'Outfit',
    GroupIcon = 'GroupIcon',
    DeveloperProduct = 'DeveloperProduct',
    AvatarBust = 'AvatarBust',
    AutoGeneratedAsset = 'AutoGeneratedAsset',
    PlaceIcon = 'PlaceIcon',
    AutoGeneratedGameIcon = 'AutoGeneratedGameIcon',
    ForceAutoGeneratedGameIcon = 'ForceAutoGeneratedGameIcon',
    LookThumbnail = 'Look',
    CreatorContextAsset = 'CreatorContextAsset',
    Screenshot = 'Screenshot'
}

/**
 * 
 * @export
 * @interface RobloxThumbsImageParameters
 */
export interface RobloxThumbsImageParameters {
    /**
     * 
     * @type {number}
     * @memberof RobloxThumbsImageParameters
     */
    Width?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxThumbsImageParameters
     */
    Height?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxThumbsImageParameters
     */
    Format?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxThumbsImageParameters
     */
    ThumbnailFormatID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxThumbsImageParameters
     */
    IsCircular?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobloxThumbsImageParameters
     */
    FileExtension?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxThumbsImageParameters
     */
    MimeType?: string;
}
/**
 * 
 * @export
 * @interface RobloxWebResponsesThumbnailsThumbnailBatchResponse
 */
export interface RobloxWebResponsesThumbnailsThumbnailBatchResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    requestId?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    errorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    targetId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    state?: RobloxWebResponsesThumbnailsThumbnailBatchResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    imageUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxWebResponsesThumbnailsThumbnailBatchResponseStateEnum {
    Error = 'Error',
    Completed = 'Completed',
    InReview = 'InReview',
    Pending = 'Pending',
    Blocked = 'Blocked'
}

/**
 * 
 * @export
 * @interface RobloxWebResponsesThumbnailsThumbnailResponse
 */
export interface RobloxWebResponsesThumbnailsThumbnailResponse {
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesThumbnailsThumbnailResponse
     */
    targetId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesThumbnailsThumbnailResponse
     */
    state?: RobloxWebResponsesThumbnailsThumbnailResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesThumbnailsThumbnailResponse
     */
    imageUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxWebResponsesThumbnailsThumbnailResponseStateEnum {
    Error = 'Error',
    Completed = 'Completed',
    InReview = 'InReview',
    Pending = 'Pending',
    Blocked = 'Blocked'
}

/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse {
    /**
     * 
     * @type {Array<RobloxThumbnailsApiModelsUniverseThumbnailsResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
     */
    data?: Array<RobloxThumbnailsApiModelsUniverseThumbnailsResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse {
    /**
     * 
     * @type {Array<RobloxWebResponsesThumbnailsThumbnailBatchResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
     */
    data?: Array<RobloxWebResponsesThumbnailsThumbnailBatchResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse {
    /**
     * 
     * @type {Array<RobloxWebResponsesThumbnailsThumbnailResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
     */
    data?: Array<RobloxWebResponsesThumbnailsThumbnailResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiErrorModel
 */
export interface RobloxWebWebAPIModelsApiErrorModel {
    /**
     * 
     * @type {number}
     * @memberof RobloxWebWebAPIModelsApiErrorModel
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiErrorModel
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiErrorModel
     */
    userFacingMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiErrorModel
     */
    field?: string;
    /**
     * 
     * @type {object}
     * @memberof RobloxWebWebAPIModelsApiErrorModel
     */
    fieldData?: object;
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Thumbnails assets.
         * @param {Array<number>} assetIds The asset ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
         * @param {'30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssetsGet: async (assetIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetIds' is not null or undefined
            assertParamExists('v1AssetsGet', 'assetIds', assetIds)
            const localVarPath = `/v1/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetIds) {
                localVarQueryParameter['assetIds'] = assetIds.join(COLLECTION_FORMATS.csv);
            }

            if (returnPolicy !== undefined) {
                localVarQueryParameter['returnPolicy'] = returnPolicy;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Thumbnails assets.
         * @param {number} assetId The asset id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssetsThumbnail3dGet: async (assetId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('v1AssetsThumbnail3dGet', 'assetId', assetId)
            const localVarPath = `/v1/assets-thumbnail-3d`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Thumbnails assets.
         * @param {Array<number>} assetIds The asset ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
         * @param {'30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssetsGet(assetIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AssetsGet(assetIds, returnPolicy, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Thumbnails assets.
         * @param {number} assetId The asset id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssetsThumbnail3dGet(assetId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AssetsThumbnail3dGet(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @summary Thumbnails assets.
         * @param {Array<number>} assetIds The asset ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
         * @param {'30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssetsGet(assetIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1AssetsGet(assetIds, returnPolicy, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Thumbnails assets.
         * @param {number} assetId The asset id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssetsThumbnail3dGet(assetId: number, options?: any): AxiosPromise<RobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1AssetsThumbnail3dGet(assetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - interface
 * @export
 * @interface AssetsApi
 */
export interface AssetsApiInterface {
    /**
     * 
     * @summary Thumbnails assets.
     * @param {Array<number>} assetIds The asset ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
     * @param {'30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    v1AssetsGet(assetIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

    /**
     * 
     * @summary Thumbnails assets.
     * @param {number} assetId The asset id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    v1AssetsThumbnail3dGet(assetId: number, options?: any): AxiosPromise<RobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI implements AssetsApiInterface {
    /**
     * 
     * @summary Thumbnails assets.
     * @param {Array<number>} assetIds The asset ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
     * @param {'30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public v1AssetsGet(assetIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '30x30' | '42x42' | '50x50' | '60x62' | '75x75' | '110x110' | '140x140' | '150x150' | '160x100' | '160x600' | '250x250' | '256x144' | '300x250' | '304x166' | '384x216' | '396x216' | '420x420' | '480x270' | '512x512' | '576x324' | '700x700' | '728x90' | '768x432', format?: number, isCircular?: boolean, options?: any) {
        return AssetsApiFp(this.configuration).v1AssetsGet(assetIds, returnPolicy, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Thumbnails assets.
     * @param {number} assetId The asset id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public v1AssetsThumbnail3dGet(assetId: number, options?: any) {
        return AssetsApiFp(this.configuration).v1AssetsThumbnail3dGet(assetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvatarApi - axios parameter creator
 * @export
 */
export const AvatarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Avatar 3d object for a user
         * @param {number} userId user Id for avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatar3dGet: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v1UsersAvatar3dGet', 'userId', userId)
            const localVarPath = `/v1/users/avatar-3d`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Avatar Busts for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
         * @param {'50x50' | '60x60' | '75x75'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatarBustGet: async (userIds: Array<number>, size?: '50x50' | '60x60' | '75x75', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIds' is not null or undefined
            assertParamExists('v1UsersAvatarBustGet', 'userIds', userIds)
            const localVarPath = `/v1/users/avatar-bust`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Avatar Full body shots for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
         * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatarGet: async (userIds: Array<number>, size?: '30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIds' is not null or undefined
            assertParamExists('v1UsersAvatarGet', 'userIds', userIds)
            const localVarPath = `/v1/users/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Avatar Headshots for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
         * @param {'48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatarHeadshotGet: async (userIds: Array<number>, size?: '48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIds' is not null or undefined
            assertParamExists('v1UsersAvatarHeadshotGet', 'userIds', userIds)
            const localVarPath = `/v1/users/avatar-headshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvatarApi - functional programming interface
 * @export
 */
export const AvatarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvatarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Avatar 3d object for a user
         * @param {number} userId user Id for avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAvatar3dGet(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersAvatar3dGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Avatar Busts for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
         * @param {'50x50' | '60x60' | '75x75'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAvatarBustGet(userIds: Array<number>, size?: '50x50' | '60x60' | '75x75', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersAvatarBustGet(userIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Avatar Full body shots for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
         * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAvatarGet(userIds: Array<number>, size?: '30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersAvatarGet(userIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Avatar Headshots for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
         * @param {'48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersAvatarHeadshotGet(userIds: Array<number>, size?: '48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvatarApi - factory interface
 * @export
 */
export const AvatarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvatarApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Avatar 3d object for a user
         * @param {number} userId user Id for avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatar3dGet(userId: number, options?: any): AxiosPromise<RobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1UsersAvatar3dGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Avatar Busts for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
         * @param {'50x50' | '60x60' | '75x75'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatarBustGet(userIds: Array<number>, size?: '50x50' | '60x60' | '75x75', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1UsersAvatarBustGet(userIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Avatar Full body shots for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
         * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatarGet(userIds: Array<number>, size?: '30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1UsersAvatarGet(userIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Avatar Headshots for the given CSV of userIds
         * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
         * @param {'48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAvatarHeadshotGet(userIds: Array<number>, size?: '48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvatarApi - interface
 * @export
 * @interface AvatarApi
 */
export interface AvatarApiInterface {
    /**
     * 
     * @summary Get Avatar 3d object for a user
     * @param {number} userId user Id for avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApiInterface
     */
    v1UsersAvatar3dGet(userId: number, options?: any): AxiosPromise<RobloxWebResponsesThumbnailsThumbnailResponse>;

    /**
     * 
     * @summary Get Avatar Busts for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'50x50' | '60x60' | '75x75'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApiInterface
     */
    v1UsersAvatarBustGet(userIds: Array<number>, size?: '50x50' | '60x60' | '75x75', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

    /**
     * 
     * @summary Get Avatar Full body shots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
     * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApiInterface
     */
    v1UsersAvatarGet(userIds: Array<number>, size?: '30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

    /**
     * 
     * @summary Get Avatar Headshots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApiInterface
     */
    v1UsersAvatarHeadshotGet(userIds: Array<number>, size?: '48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * AvatarApi - object-oriented interface
 * @export
 * @class AvatarApi
 * @extends {BaseAPI}
 */
export class AvatarApi extends BaseAPI implements AvatarApiInterface {
    /**
     * 
     * @summary Get Avatar 3d object for a user
     * @param {number} userId user Id for avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApi
     */
    public v1UsersAvatar3dGet(userId: number, options?: any) {
        return AvatarApiFp(this.configuration).v1UsersAvatar3dGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Avatar Busts for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'50x50' | '60x60' | '75x75'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApi
     */
    public v1UsersAvatarBustGet(userIds: Array<number>, size?: '50x50' | '60x60' | '75x75', format?: number, isCircular?: boolean, options?: any) {
        return AvatarApiFp(this.configuration).v1UsersAvatarBustGet(userIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Avatar Full body shots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
     * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApi
     */
    public v1UsersAvatarGet(userIds: Array<number>, size?: '30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any) {
        return AvatarApiFp(this.configuration).v1UsersAvatarGet(userIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Avatar Headshots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarApi
     */
    public v1UsersAvatarHeadshotGet(userIds: Array<number>, size?: '48x48' | '50x50' | '60x60' | '75x75' | '100x100' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720', format?: number, isCircular?: boolean, options?: any) {
        return AvatarApiFp(this.configuration).v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BadgesApi - axios parameter creator
 * @export
 */
export const BadgesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Thumbnails badge icons.
         * @param {Array<number>} badgeIds The badge ids.
         * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesIconsGet: async (badgeIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeIds' is not null or undefined
            assertParamExists('v1BadgesIconsGet', 'badgeIds', badgeIds)
            const localVarPath = `/v1/badges/icons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (badgeIds) {
                localVarQueryParameter['badgeIds'] = badgeIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BadgesApi - functional programming interface
 * @export
 */
export const BadgesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BadgesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Thumbnails badge icons.
         * @param {Array<number>} badgeIds The badge ids.
         * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesIconsGet(badgeIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BadgesIconsGet(badgeIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BadgesApi - factory interface
 * @export
 */
export const BadgesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BadgesApiFp(configuration)
    return {
        /**
         * 
         * @summary Thumbnails badge icons.
         * @param {Array<number>} badgeIds The badge ids.
         * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesIconsGet(badgeIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1BadgesIconsGet(badgeIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BadgesApi - interface
 * @export
 * @interface BadgesApi
 */
export interface BadgesApiInterface {
    /**
     * 
     * @summary Thumbnails badge icons.
     * @param {Array<number>} badgeIds The badge ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgesApiInterface
     */
    v1BadgesIconsGet(badgeIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * BadgesApi - object-oriented interface
 * @export
 * @class BadgesApi
 * @extends {BaseAPI}
 */
export class BadgesApi extends BaseAPI implements BadgesApiInterface {
    /**
     * 
     * @summary Thumbnails badge icons.
     * @param {Array<number>} badgeIds The badge ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgesApi
     */
    public v1BadgesIconsGet(badgeIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any) {
        return BadgesApiFp(this.configuration).v1BadgesIconsGet(badgeIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of thumbnails with varying types and sizes
         * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects, may contain the following request types:              1. Avatar              2. AvatarHeadShot              3. GameIcon              4. BadgeIcon              5. GameThumbnail              6. GamePass              7. Asset              8. BundleThumbnail              9. Outfit              10. GroupIcon              11. DeveloperProduct              12. AutoGeneratedAsset              13. AvatarBust
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BatchPost: async (requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requests' is not null or undefined
            assertParamExists('v1BatchPost', 'requests', requests)
            const localVarPath = `/v1/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requests, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a list of thumbnails with varying types and sizes
         * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects, may contain the following request types:              1. Avatar              2. AvatarHeadShot              3. GameIcon              4. BadgeIcon              5. GameThumbnail              6. GamePass              7. Asset              8. BundleThumbnail              9. Outfit              10. GroupIcon              11. DeveloperProduct              12. AutoGeneratedAsset              13. AvatarBust
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BatchPost(requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BatchPost(requests, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a list of thumbnails with varying types and sizes
         * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects, may contain the following request types:              1. Avatar              2. AvatarHeadShot              3. GameIcon              4. BadgeIcon              5. GameThumbnail              6. GamePass              7. Asset              8. BundleThumbnail              9. Outfit              10. GroupIcon              11. DeveloperProduct              12. AutoGeneratedAsset              13. AvatarBust
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BatchPost(requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse> {
            return localVarFp.v1BatchPost(requests, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchApi - interface
 * @export
 * @interface BatchApi
 */
export interface BatchApiInterface {
    /**
     * 
     * @summary Returns a list of thumbnails with varying types and sizes
     * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects, may contain the following request types:              1. Avatar              2. AvatarHeadShot              3. GameIcon              4. BadgeIcon              5. GameThumbnail              6. GamePass              7. Asset              8. BundleThumbnail              9. Outfit              10. GroupIcon              11. DeveloperProduct              12. AutoGeneratedAsset              13. AvatarBust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApiInterface
     */
    v1BatchPost(requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse>;

}

/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI implements BatchApiInterface {
    /**
     * 
     * @summary Returns a list of thumbnails with varying types and sizes
     * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects, may contain the following request types:              1. Avatar              2. AvatarHeadShot              3. GameIcon              4. BadgeIcon              5. GameThumbnail              6. GamePass              7. Asset              8. BundleThumbnail              9. Outfit              10. GroupIcon              11. DeveloperProduct              12. AutoGeneratedAsset              13. AvatarBust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public v1BatchPost(requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>, options?: any) {
        return BatchApiFp(this.configuration).v1BatchPost(requests, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BundlesApi - axios parameter creator
 * @export
 */
export const BundlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get bundle thumbnails for the given CSV of bundle ids
         * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BundlesThumbnailsGet: async (bundleIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bundleIds' is not null or undefined
            assertParamExists('v1BundlesThumbnailsGet', 'bundleIds', bundleIds)
            const localVarPath = `/v1/bundles/thumbnails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bundleIds) {
                localVarQueryParameter['bundleIds'] = bundleIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BundlesApi - functional programming interface
 * @export
 */
export const BundlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BundlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get bundle thumbnails for the given CSV of bundle ids
         * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BundlesThumbnailsGet(bundleIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BundlesApi - factory interface
 * @export
 */
export const BundlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BundlesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get bundle thumbnails for the given CSV of bundle ids
         * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BundlesThumbnailsGet(bundleIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BundlesApi - interface
 * @export
 * @interface BundlesApi
 */
export interface BundlesApiInterface {
    /**
     * 
     * @summary Get bundle thumbnails for the given CSV of bundle ids
     * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApiInterface
     */
    v1BundlesThumbnailsGet(bundleIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * BundlesApi - object-oriented interface
 * @export
 * @class BundlesApi
 * @extends {BaseAPI}
 */
export class BundlesApi extends BaseAPI implements BundlesApiInterface {
    /**
     * 
     * @summary Get bundle thumbnails for the given CSV of bundle ids
     * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BundlesApi
     */
    public v1BundlesThumbnailsGet(bundleIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any) {
        return BundlesApiFp(this.configuration).v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeveloperProductsApi - axios parameter creator
 * @export
 */
export const DeveloperProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Thumbnails developer product icons.
         * @param {Array<number>} developerProductIds The developer product ids.
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsIconsGet: async (developerProductIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductIds' is not null or undefined
            assertParamExists('v1DeveloperProductsIconsGet', 'developerProductIds', developerProductIds)
            const localVarPath = `/v1/developer-products/icons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (developerProductIds) {
                localVarQueryParameter['developerProductIds'] = developerProductIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeveloperProductsApi - functional programming interface
 * @export
 */
export const DeveloperProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeveloperProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Thumbnails developer product icons.
         * @param {Array<number>} developerProductIds The developer product ids.
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsIconsGet(developerProductIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeveloperProductsIconsGet(developerProductIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeveloperProductsApi - factory interface
 * @export
 */
export const DeveloperProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeveloperProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Thumbnails developer product icons.
         * @param {Array<number>} developerProductIds The developer product ids.
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsIconsGet(developerProductIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1DeveloperProductsIconsGet(developerProductIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeveloperProductsApi - interface
 * @export
 * @interface DeveloperProductsApi
 */
export interface DeveloperProductsApiInterface {
    /**
     * 
     * @summary Thumbnails developer product icons.
     * @param {Array<number>} developerProductIds The developer product ids.
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductsApiInterface
     */
    v1DeveloperProductsIconsGet(developerProductIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * DeveloperProductsApi - object-oriented interface
 * @export
 * @class DeveloperProductsApi
 * @extends {BaseAPI}
 */
export class DeveloperProductsApi extends BaseAPI implements DeveloperProductsApiInterface {
    /**
     * 
     * @summary Thumbnails developer product icons.
     * @param {Array<number>} developerProductIds The developer product ids.
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductsApi
     */
    public v1DeveloperProductsIconsGet(developerProductIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any) {
        return DeveloperProductsApiFp(this.configuration).v1DeveloperProductsIconsGet(developerProductIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GamePassesApi - axios parameter creator
 * @export
 */
export const GamePassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Thumbnails game pass icons.
         * @param {Array<number>} gamePassIds The game pass ids.
         * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGet: async (gamePassIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassIds' is not null or undefined
            assertParamExists('v1GamePassesGet', 'gamePassIds', gamePassIds)
            const localVarPath = `/v1/game-passes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamePassIds) {
                localVarQueryParameter['gamePassIds'] = gamePassIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamePassesApi - functional programming interface
 * @export
 */
export const GamePassesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GamePassesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Thumbnails game pass icons.
         * @param {Array<number>} gamePassIds The game pass ids.
         * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGet(gamePassIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GamePassesGet(gamePassIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GamePassesApi - factory interface
 * @export
 */
export const GamePassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GamePassesApiFp(configuration)
    return {
        /**
         * 
         * @summary Thumbnails game pass icons.
         * @param {Array<number>} gamePassIds The game pass ids.
         * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGet(gamePassIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1GamePassesGet(gamePassIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GamePassesApi - interface
 * @export
 * @interface GamePassesApi
 */
export interface GamePassesApiInterface {
    /**
     * 
     * @summary Thumbnails game pass icons.
     * @param {Array<number>} gamePassIds The game pass ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassesApiInterface
     */
    v1GamePassesGet(gamePassIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * GamePassesApi - object-oriented interface
 * @export
 * @class GamePassesApi
 * @extends {BaseAPI}
 */
export class GamePassesApi extends BaseAPI implements GamePassesApiInterface {
    /**
     * 
     * @summary Thumbnails game pass icons.
     * @param {Array<number>} gamePassIds The game pass ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassesApi
     */
    public v1GamePassesGet(gamePassIds: Array<number>, size?: '150x150', format?: number, isCircular?: boolean, options?: any) {
        return GamePassesApiFp(this.configuration).v1GamePassesGet(gamePassIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GamesApi - axios parameter creator
 * @export
 */
export const GamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
         * @param {Array<number>} universeIds The universe ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
         * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesIconsGet: async (universeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeIds' is not null or undefined
            assertParamExists('v1GamesIconsGet', 'universeIds', universeIds)
            const localVarPath = `/v1/games/icons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universeIds) {
                localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
            }

            if (returnPolicy !== undefined) {
                localVarQueryParameter['returnPolicy'] = returnPolicy;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch game thumbnail URLs for a list of universe IDs.
         * @param {Array<number>} universeIds comma-delimited list of universe IDs
         * @param {number} [countPerUniverse] max number of thumbnails to return per universe
         * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
         * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetThumbnailsGet: async (universeIds: Array<number>, countPerUniverse?: number, defaults?: boolean, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeIds' is not null or undefined
            assertParamExists('v1GamesMultigetThumbnailsGet', 'universeIds', universeIds)
            const localVarPath = `/v1/games/multiget/thumbnails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universeIds) {
                localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
            }

            if (countPerUniverse !== undefined) {
                localVarQueryParameter['countPerUniverse'] = countPerUniverse;
            }

            if (defaults !== undefined) {
                localVarQueryParameter['defaults'] = defaults;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
         * @param {number} universeId 
         * @param {Array<number>} thumbnailIds 
         * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdThumbnailsGet: async (universeId: number, thumbnailIds: Array<number>, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeId' is not null or undefined
            assertParamExists('v1GamesUniverseIdThumbnailsGet', 'universeId', universeId)
            // verify required parameter 'thumbnailIds' is not null or undefined
            assertParamExists('v1GamesUniverseIdThumbnailsGet', 'thumbnailIds', thumbnailIds)
            const localVarPath = `/v1/games/{universeId}/thumbnails`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (thumbnailIds) {
                localVarQueryParameter['thumbnailIds'] = thumbnailIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamesApi - functional programming interface
 * @export
 */
export const GamesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GamesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
         * @param {Array<number>} universeIds The universe ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
         * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamesIconsGet(universeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GamesIconsGet(universeIds, returnPolicy, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch game thumbnail URLs for a list of universe IDs.
         * @param {Array<number>} universeIds comma-delimited list of universe IDs
         * @param {number} [countPerUniverse] max number of thumbnails to return per universe
         * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
         * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamesMultigetThumbnailsGet(universeIds: Array<number>, countPerUniverse?: number, defaults?: boolean, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GamesMultigetThumbnailsGet(universeIds, countPerUniverse, defaults, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
         * @param {number} universeId 
         * @param {Array<number>} thumbnailIds 
         * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamesUniverseIdThumbnailsGet(universeId: number, thumbnailIds: Array<number>, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GamesUniverseIdThumbnailsGet(universeId, thumbnailIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GamesApi - factory interface
 * @export
 */
export const GamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GamesApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
         * @param {Array<number>} universeIds The universe ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
         * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesIconsGet(universeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1GamesIconsGet(universeIds, returnPolicy, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch game thumbnail URLs for a list of universe IDs.
         * @param {Array<number>} universeIds comma-delimited list of universe IDs
         * @param {number} [countPerUniverse] max number of thumbnails to return per universe
         * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
         * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetThumbnailsGet(universeIds: Array<number>, countPerUniverse?: number, defaults?: boolean, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse> {
            return localVarFp.v1GamesMultigetThumbnailsGet(universeIds, countPerUniverse, defaults, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
         * @param {number} universeId 
         * @param {Array<number>} thumbnailIds 
         * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdThumbnailsGet(universeId: number, thumbnailIds: Array<number>, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1GamesUniverseIdThumbnailsGet(universeId, thumbnailIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GamesApi - interface
 * @export
 * @interface GamesApi
 */
export interface GamesApiInterface {
    /**
     * 
     * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
     * @param {Array<number>} universeIds The universe ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesIconsGet(universeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

    /**
     * 
     * @summary Fetch game thumbnail URLs for a list of universe IDs.
     * @param {Array<number>} universeIds comma-delimited list of universe IDs
     * @param {number} [countPerUniverse] max number of thumbnails to return per universe
     * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesMultigetThumbnailsGet(universeIds: Array<number>, countPerUniverse?: number, defaults?: boolean, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse>;

    /**
     * 
     * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
     * @param {number} universeId 
     * @param {Array<number>} thumbnailIds 
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesUniverseIdThumbnailsGet(universeId: number, thumbnailIds: Array<number>, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * GamesApi - object-oriented interface
 * @export
 * @class GamesApi
 * @extends {BaseAPI}
 */
export class GamesApi extends BaseAPI implements GamesApiInterface {
    /**
     * 
     * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
     * @param {Array<number>} universeIds The universe ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesIconsGet(universeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any) {
        return GamesApiFp(this.configuration).v1GamesIconsGet(universeIds, returnPolicy, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch game thumbnail URLs for a list of universe IDs.
     * @param {Array<number>} universeIds comma-delimited list of universe IDs
     * @param {number} [countPerUniverse] max number of thumbnails to return per universe
     * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesMultigetThumbnailsGet(universeIds: Array<number>, countPerUniverse?: number, defaults?: boolean, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any) {
        return GamesApiFp(this.configuration).v1GamesMultigetThumbnailsGet(universeIds, countPerUniverse, defaults, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
     * @param {number} universeId 
     * @param {Array<number>} thumbnailIds 
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesUniverseIdThumbnailsGet(universeId: number, thumbnailIds: Array<number>, size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144', format?: number, isCircular?: boolean, options?: any) {
        return GamesApiFp(this.configuration).v1GamesUniverseIdThumbnailsGet(universeId, thumbnailIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupEmblemApi - axios parameter creator
 * @export
 */
export const GroupEmblemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
         * @param {Array<number>} groupIds 
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GroupsIconsGet: async (groupIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupIds' is not null or undefined
            assertParamExists('v1GroupsIconsGet', 'groupIds', groupIds)
            const localVarPath = `/v1/groups/icons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupIds) {
                localVarQueryParameter['groupIds'] = groupIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupEmblemApi - functional programming interface
 * @export
 */
export const GroupEmblemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupEmblemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
         * @param {Array<number>} groupIds 
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GroupsIconsGet(groupIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GroupsIconsGet(groupIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupEmblemApi - factory interface
 * @export
 */
export const GroupEmblemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupEmblemApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
         * @param {Array<number>} groupIds 
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GroupsIconsGet(groupIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1GroupsIconsGet(groupIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupEmblemApi - interface
 * @export
 * @interface GroupEmblemApi
 */
export interface GroupEmblemApiInterface {
    /**
     * 
     * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
     * @param {Array<number>} groupIds 
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEmblemApiInterface
     */
    v1GroupsIconsGet(groupIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * GroupEmblemApi - object-oriented interface
 * @export
 * @class GroupEmblemApi
 * @extends {BaseAPI}
 */
export class GroupEmblemApi extends BaseAPI implements GroupEmblemApiInterface {
    /**
     * 
     * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
     * @param {Array<number>} groupIds 
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEmblemApi
     */
    public v1GroupsIconsGet(groupIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any) {
        return GroupEmblemApiFp(this.configuration).v1GroupsIconsGet(groupIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetaDataApi - axios parameter creator
 * @export
 */
export const MetaDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of thumbnail meta data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MetadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaDataApi - functional programming interface
 * @export
 */
export const MetaDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetaDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of thumbnail meta data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MetadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxThumbnailsApiModelsThumbnailMetaDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1MetadataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetaDataApi - factory interface
 * @export
 */
export const MetaDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetaDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of thumbnail meta data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MetadataGet(options?: any): AxiosPromise<RobloxThumbnailsApiModelsThumbnailMetaDataResponse> {
            return localVarFp.v1MetadataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetaDataApi - interface
 * @export
 * @interface MetaDataApi
 */
export interface MetaDataApiInterface {
    /**
     * 
     * @summary Return list of thumbnail meta data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataApiInterface
     */
    v1MetadataGet(options?: any): AxiosPromise<RobloxThumbnailsApiModelsThumbnailMetaDataResponse>;

}

/**
 * MetaDataApi - object-oriented interface
 * @export
 * @class MetaDataApi
 * @extends {BaseAPI}
 */
export class MetaDataApi extends BaseAPI implements MetaDataApiInterface {
    /**
     * 
     * @summary Return list of thumbnail meta data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataApi
     */
    public v1MetadataGet(options?: any) {
        return MetaDataApiFp(this.configuration).v1MetadataGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OutfitsApi - axios parameter creator
 * @export
 */
export const OutfitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get outfits for the given CSV of userIds
         * @param {Array<number>} userOutfitIds 
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersOutfitsGet: async (userOutfitIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userOutfitIds' is not null or undefined
            assertParamExists('v1UsersOutfitsGet', 'userOutfitIds', userOutfitIds)
            const localVarPath = `/v1/users/outfits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userOutfitIds) {
                localVarQueryParameter['userOutfitIds'] = userOutfitIds.join(COLLECTION_FORMATS.csv);
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutfitsApi - functional programming interface
 * @export
 */
export const OutfitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutfitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get outfits for the given CSV of userIds
         * @param {Array<number>} userOutfitIds 
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UsersOutfitsGet(userOutfitIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UsersOutfitsGet(userOutfitIds, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OutfitsApi - factory interface
 * @export
 */
export const OutfitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutfitsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get outfits for the given CSV of userIds
         * @param {Array<number>} userOutfitIds 
         * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersOutfitsGet(userOutfitIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1UsersOutfitsGet(userOutfitIds, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutfitsApi - interface
 * @export
 * @interface OutfitsApi
 */
export interface OutfitsApiInterface {
    /**
     * 
     * @summary Get outfits for the given CSV of userIds
     * @param {Array<number>} userOutfitIds 
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutfitsApiInterface
     */
    v1UsersOutfitsGet(userOutfitIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * OutfitsApi - object-oriented interface
 * @export
 * @class OutfitsApi
 * @extends {BaseAPI}
 */
export class OutfitsApi extends BaseAPI implements OutfitsApiInterface {
    /**
     * 
     * @summary Get outfits for the given CSV of userIds
     * @param {Array<number>} userOutfitIds 
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutfitsApi
     */
    public v1UsersOutfitsGet(userOutfitIds: Array<number>, size?: '150x150' | '420x420', format?: number, isCircular?: boolean, options?: any) {
        return OutfitsApiFp(this.configuration).v1UsersOutfitsGet(userOutfitIds, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlacesApi - axios parameter creator
 * @export
 */
export const PlacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetches game icon URLs for a list of places. Ids that do not correspond to a valid place will be filtered out.
         * @param {Array<number>} placeIds The place ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
         * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlacesGameiconsGet: async (placeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeIds' is not null or undefined
            assertParamExists('v1PlacesGameiconsGet', 'placeIds', placeIds)
            const localVarPath = `/v1/places/gameicons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (placeIds) {
                localVarQueryParameter['placeIds'] = placeIds.join(COLLECTION_FORMATS.csv);
            }

            if (returnPolicy !== undefined) {
                localVarQueryParameter['returnPolicy'] = returnPolicy;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (isCircular !== undefined) {
                localVarQueryParameter['isCircular'] = isCircular;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlacesApi - functional programming interface
 * @export
 */
export const PlacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetches game icon URLs for a list of places. Ids that do not correspond to a valid place will be filtered out.
         * @param {Array<number>} placeIds The place ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
         * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PlacesGameiconsGet(placeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PlacesGameiconsGet(placeIds, returnPolicy, size, format, isCircular, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlacesApi - factory interface
 * @export
 */
export const PlacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlacesApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetches game icon URLs for a list of places. Ids that do not correspond to a valid place will be filtered out.
         * @param {Array<number>} placeIds The place ids.
         * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
         * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
         * @param {number} [format] The thumbnail format
         * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlacesGameiconsGet(placeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> {
            return localVarFp.v1PlacesGameiconsGet(placeIds, returnPolicy, size, format, isCircular, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlacesApi - interface
 * @export
 * @interface PlacesApi
 */
export interface PlacesApiInterface {
    /**
     * 
     * @summary Fetches game icon URLs for a list of places. Ids that do not correspond to a valid place will be filtered out.
     * @param {Array<number>} placeIds The place ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacesApiInterface
     */
    v1PlacesGameiconsGet(placeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse>;

}

/**
 * PlacesApi - object-oriented interface
 * @export
 * @class PlacesApi
 * @extends {BaseAPI}
 */
export class PlacesApi extends BaseAPI implements PlacesApiInterface {
    /**
     * 
     * @summary Fetches game icon URLs for a list of places. Ids that do not correspond to a valid place will be filtered out.
     * @param {Array<number>} placeIds The place ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlacesApi
     */
    public v1PlacesGameiconsGet(placeIds: Array<number>, returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated', size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512', format?: number, isCircular?: boolean, options?: any) {
        return PlacesApiFp(this.configuration).v1PlacesGameiconsGet(placeIds, returnPolicy, size, format, isCircular, options).then((request) => request(this.axios, this.basePath));
    }
}


