const UPSELL_COOKIE_KEY = 'RBXCatalogUpsellData';
const UPSELL_COOKIE_KEY_REGEX = /RBXCatalogUpsellData=([^;]+)/;
const UPSELL_QUERY_PARAM_KEY = 'UpsellUuid';
const UPSELL_TARGET_ITEM_URL_COOKIE_DATA_REGEX = /((\/[\w-]+)+)\/(\d+)/g;
const UPSELL_TARGET_ITEM_URL_REGEX = /((\/[\w-]+)+)\/(\d+)/g;

export {
  UPSELL_COOKIE_KEY,
  UPSELL_COOKIE_KEY_REGEX,
  UPSELL_QUERY_PARAM_KEY,
  UPSELL_TARGET_ITEM_URL_COOKIE_DATA_REGEX,
  UPSELL_TARGET_ITEM_URL_REGEX
};
